import { bleskHeader } from '../../../default/src/scripts/modules/blesk-header';

bleskHeader();

/**
 * Modul ipadChangeViewport zajistuje na iPadu upravu meta tagu viewport a pridani provizornich CSS
 *
 * Dynamicky import modulu, pokud je detekován iPad
 */
const dynamicIpadChangeViewport = async () => {
    if (document.documentElement.clientWidth <= 1024 && navigator.userAgent.match(/iPad/i)) {
        const { ipadChangeViewport } = await import('./modules/ipad-change-viewport');

        ipadChangeViewport();
    }
};

/** Spusteni dynamickych importu */
const loadDynamicImports = () => {
    dynamicIpadChangeViewport();
};

loadDynamicImports();
